exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-eden-js": () => import("./../../../src/pages/eden.js" /* webpackChunkName: "component---src-pages-eden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-amp-js": () => import("./../../../src/templates/blog-post-amp.js" /* webpackChunkName: "component---src-templates-blog-post-amp-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-info-list-js": () => import("./../../../src/templates/info-list.js" /* webpackChunkName: "component---src-templates-info-list-js" */),
  "component---src-templates-info-post-amp-js": () => import("./../../../src/templates/info-post-amp.js" /* webpackChunkName: "component---src-templates-info-post-amp-js" */),
  "component---src-templates-info-post-js": () => import("./../../../src/templates/info-post.js" /* webpackChunkName: "component---src-templates-info-post-js" */),
  "component---src-templates-news-post-amp-js": () => import("./../../../src/templates/news-post-amp.js" /* webpackChunkName: "component---src-templates-news-post-amp-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

